import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Text, Box } from 'grommet';
import { IImage } from '../utils/componentInterfaces';
import { MaxWidth } from './maxWidth';

interface Props {
    image: IImage;
    ccaption?: string;
}
const StyleImageWrap = styled.div`
    border-radius: 12px;
    img {
        border-radius: 12px;
        ${({ hasCaption }) => css`
            ${hasCaption &&
                `
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
        `}
        `}
    }
`;

const StandoutImage: FC<Props> = ({ image, caption }) => (
    <div className="light-1">
        <MaxWidth pad={{ vertical: 'xlarge' }} className="block" style={{ maxWidth: '1000px' }}>
            <StyleImageWrap hasCaption={!!caption}>
                {image && (
                    <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.altText || ''} />
                )}
                {caption && (
                    <Box background="accent-1" pad="xsmall" round={{ size: 'small', corner: 'bottom' }}>
                        <Text color="white" as="p" size="large" textAlign="center">
                            {caption}
                        </Text>
                    </Box>
                )}
            </StyleImageWrap>
        </MaxWidth>
    </div>
);

export default StandoutImage;
